import { NavigationItem, PartnerFragment } from '@amf/shared/types/graphql';
import { ReactNode } from 'react';
import MainLayout from '@amf/shared/components/layout/Layout';
import Navigation from '@amf/shared/components/navigation/Navigation';
import BrandMalyFotbal from '@amf/shared/icons/brands/BrandMalyFotbal';

import Footer from 'components/layout/Footer';
import { AppLinks } from 'utils/links';

interface LayoutProps {
  navigation: NavigationItem[];
  partners: PartnerFragment[];
  children: ReactNode;
}

function Layout({ navigation, partners, children }: LayoutProps) {
  return (
    <MainLayout footer={<Footer partners={partners} />}>
      <Navigation
        variant='blue'
        navigation={navigation}
        brand={<BrandMalyFotbal />}
        search={AppLinks.search}
      />
      {children}
    </MainLayout>
  );
}

export default Layout;
