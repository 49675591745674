export default function BrandMalyFotbal() {
  return (
    <svg
      width='169'
      height='80'
      viewBox='0 0 169 80'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.93551 54.8948L34.1067 76.0625L65.2509 54.915V2.96318H2.96624L2.93551 54.8948ZM0 56.4551L0.00317868 0H68.2124L68.2135 56.5134L34.1067 79.6509L0 56.4551Z'
          fill='#3BFEB8'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M17.0887 19.276L14.5361 16.7234L11.983 19.276H9.79279V17.0852L12.3454 14.5327L9.79279 11.9801V9.78882H11.983L14.5361 12.3414L17.0887 9.78882H19.2789V11.9795L16.7263 14.5327L19.2789 17.0852V19.276H17.0887Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M56.2346 19.276L53.682 16.7229L51.1289 19.276H48.9387V17.0852L51.4913 14.5327L48.9387 11.9801V9.78882H51.1289L53.682 12.3414L56.2346 9.78882H58.4243V11.9795L55.8722 14.5327L58.4243 17.0852V19.276H56.2346Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M17.0887 47.9334L14.5361 45.3808L11.983 47.9334H9.79279V45.7427L12.3454 43.1901L9.79279 40.6375V38.4468H11.983L14.5361 40.9994L17.0887 38.4468H19.2789V40.6375L16.7263 43.1901L19.2789 45.7427V47.9334H17.0887Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M56.2346 47.9334L53.682 45.3808L51.1289 47.9334H48.9387V45.7427L51.4913 43.1901L48.9387 40.6375V38.4468H51.1289L53.682 40.9994L56.2346 38.4468H58.4243V40.6375L55.8722 43.1901L58.4243 45.7427V47.9334H56.2346Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M36.6615 62.2623H38.8517V60.071L36.4056 57.6244L36.2991 57.5185L36.4056 57.4125L38.8517 54.9659V52.7751H36.6615L34.2149 55.2218L34.1089 55.3283L31.5563 52.7751H29.3656V54.9659L31.9182 57.5185L29.3656 60.071V62.2623H31.5563L34.1089 59.7092L34.2149 59.8157L36.6615 62.2623Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M36.6615 33.6111L34.1089 31.0586L31.5558 33.6111H29.3656V31.4204L31.9182 28.8678L29.3656 26.3152V24.1245H31.5558L34.1089 26.6771L36.6615 24.1245H38.8517V26.3152L36.2991 28.8678L38.8517 31.4204V33.6111H36.6615Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M97.4307 34.1814C96.2153 34.1814 95.6966 34.6853 95.6966 35.8672V43.6034C95.6966 44.7849 96.2153 45.2887 97.4307 45.2887H100.25C101.466 45.2887 101.984 44.7849 101.984 43.6034V35.8672C101.984 34.6853 101.466 34.1814 100.25 34.1814H97.4307ZM96.1295 48.1597C93.4688 48.1597 92.1761 46.8977 92.1761 44.3022V35.1679C92.1761 32.5729 93.4688 31.311 96.1295 31.311H101.552C104.212 31.311 105.505 32.5729 105.505 35.1679V44.3022C105.505 46.8977 104.212 48.1597 101.552 48.1597H96.1295Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M112.864 47.9184V34.4222H107.972V31.5518H121.277V34.4222H116.385V47.9184H112.864Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M127.365 38.2273H131.171C132.404 38.2273 132.929 37.7377 132.929 36.5902V36.0355C132.929 34.871 132.404 34.3746 131.171 34.3746H127.365V38.2273ZM127.365 45.0962H131.533C132.765 45.0962 133.291 44.5923 133.291 43.4103V42.6877C133.291 41.5052 132.765 41.0013 131.533 41.0013H127.365V45.0962ZM123.844 47.9184V31.5518H132.473C135.112 31.5518 136.45 32.8498 136.45 35.4093V36.3973C136.45 37.5539 136.023 38.2988 134.976 38.9695L134.335 39.3785L135.03 39.6869C136.329 40.2617 136.812 41.0813 136.812 42.7115V44.0609C136.812 46.6204 135.481 47.9184 132.858 47.9184H123.844Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M157.379 47.9184V31.5518H160.9V45.0474H168.491V47.9184H157.379Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M103.559 19.3718H108.078L105.806 12.2433L103.559 19.3718ZM110.214 26.1363L109.003 22.336H102.61L101.399 26.1363H97.7029L103.066 9.83643H108.571L113.911 26.1363H110.214Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M144.386 41.121H148.867L146.614 33.9883L144.386 41.121ZM150.985 47.8855L149.784 44.0853H143.444L142.244 47.8855H138.582L143.899 31.5851H149.354L154.647 47.8855H150.985Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M91.5829 26.1696V15.1694L87.1898 22.0971H86.1217L81.7281 15.1408V26.1696H78.2076V9.80249H81.7678L86.669 17.5328L91.5443 9.80249H95.104V26.1696H91.5829Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M116.643 26.1696V9.80249H120.164V23.2986H127.755V26.1696H116.643Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M131.656 5.11893L138.199 2.52661L139.083 4.80211L132.54 7.3939L131.656 5.11893ZM132.621 26.1695V20.6177L126.753 9.8029H130.642L134.407 16.8694L138.146 9.8029H142.034L136.166 20.6177V26.1695H132.621Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M78.2075 47.9184V31.5518H89.585V34.4222H81.7286V38.6368H88.5725V41.5078H81.7286V47.9184H78.2075Z'
          fill='white'
        />
      </g>
    </svg>
  );
}
