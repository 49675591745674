export enum AppLinks {
  homepage = '/',
  newsList = '/novinky',
  unionList = '/svazy',
  tournamentList = '/turnaje',
  tournamentNew = '/turnaje/novy',
  cnp = '/souteze/cesky-narodni-pohar',
  superfinale = '/souteze/superfinale',
  registerPlayer = '/pridej-se/stan-se-hracem',
  registerTeam = '/pridej-se/zaloz-tym',
  registerLeague = '/pridej-se/zaloz-ligu',
  registerReferee = '/pridej-se/stan-se-rozhodcim',
  league = '/cnp/liga',
  matches = '/cnp/zapasy',
  match = '/cnp/zapas',
  player = '/cnp/hrac',
  season = '/cnp/sezony',
  team = '/cnp/tym',
  table = '/cnp/tabulka',
  ground = '/cnp/hriste',
  club = '/cnp/klub',
  stats = '/cnp/statistiky',
  statsLeague = '/cnp/statistiky/ligy',
  referee = '/cnp/rozhodci',
  search = '/vyhledavani',
  draws = '/losovani',
}

export enum ExternalLinks {
  superliga = 'https://superliga.cz/',
  playerPortal = 'https://portal.malyfotbal.cz',
  registerPlayer = 'https://portal.malyfotbal.cz/player/register',
  registerTeam = 'https://portal.malyfotbal.cz/player/{{union}}/club/create',
  registerLeague = 'https://portal.malyfotbal.cz',
  registerReferee = 'https://portal.malyfotbal.cz/referee',
  emf = 'https://www.eurominifootball.com/',
  wmf = 'http://www.minifootball.com/',
}
